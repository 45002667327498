export default {
  name: "Lotta Holmström",
  title: "Translation team lead",
  phone: "+358 40 753 4903",
  email: "lotta.holmstrom@oriola.com",
  translationsDepartment: {
    name: "Translations",
    email: "translations@oriola.com ",
  },
  regulatoryAffairsDepartment: {
    name: "Regulatory Affairs",
    email: "regulatoryaffairs@oriola.com",
  },
};
